import { extendTheme } from "@chakra-ui/react";

export default extendTheme({
  space: {
    siteHeaderDesktop: "6rem",
    siteHeaderMobile: "4rem",
  },
  breakpoints: {
    sm: "48em",
    md: "50em",
    lg: "62em",
    xl: "80em",
    "2xl": "96em",
  },
  colors: {
    beige: "#F4F4F2",
    nearBlack: "#150a0d",
    brandYellow: "#D2BA3B",
    brand: {
      50: "#fcf8df",
      100: "#f2e9bc",
      200: "#e7da94",
      300: "#decc6d",
      400: "#d4bd44",
      500: "#bba42b",
      600: "#917f1f",
      700: "#685b14",
      800: "#3e3707",
      900: "#181200",
    },
    transparent: {
      50: "#D2BA3B",
      100: "#f2e9bc",
      200: "#e7da94",
      300: "#decc6d",
      400: "#d4bd44",
      500: "transparent",
      600: "#917f1f",
      700: "#685b14",
      800: "#3e3707",
      900: "#181200",
    },

    black: {
      50: "#f2e9bc",
      100: "#dcd8d9",
      200: "#bfbfbf",
      300: "#a6a6a6",
      400: "#8c8c8c",
      500: "#150a0d",
      600: "#000000",
      700: "#000000",
      800: "#000000",
      900: "#000000",
    },
  },
  global: {
    body: {
      background: "white",
      scrollPaddingTop: "200px",
    },
  },
  fonts: {
    heading: `'Montserrat', serif`,
    body: `'Montserrat', serif`,
  },
  textStyles: {
    uppercaseHeading: {
      textTransform: "uppercase",
      fontWeight: 900,
    },
    largeBold: {
      fontSize: ["1.3rem", "1.5rem"],
      fontWeight: 700,
      textAlign: ["center", "left"],
    },
    m: {
      color: "nearBlack",
      p: {
        mb: "4",
      },
      h1: {
        fontWeight: 400,
      },
      "h1, h2, h3": {
        mb: "4",
      },
      h4: {
        mb: 1,
      },
      "li > p": {
        mb: "0",
      },
      li: {
        mb: 1,
      },
    },
    footer: {
      color: "blackAlpha.800",
      p: {
        mb: "4",
      },
      "h1, h2, h3": {
        mb: "4",
      },
      "li > p": {
        mb: "0",
      },
      li: {
        mb: 1,
      },
    },
    menuItem: {
      textTransform: "uppercase",
      size: "xl",
      "&:hover, &.active": { color: "black" },
      "&": { color: "black.400" },
    },
    mobileMenu: {
      a: { color: "white", fontSize: "2rem" },
      "a.active": { color: "white" },
      "a:hover": { color: "brandYellow" },
    },
  },
  components: {
    Input: {
      defaultProps: {
        focusBorderColor: "brandYellow",
      },
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: "brandYellow",
        resize: "vertical",
      },
    },
    Button: {
      defaultProps: {
        colorScheme: "black",
      },
    },
  },
});
